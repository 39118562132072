<template>
	<v-dialog v-model="dialog" persistent scrollable>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				class="primary--text"
				light
				:disabled="carregando"
				v-bind="attrs"
				v-on="on"
				@click="limpar()"
				elevation="0"
			>
				<v-icon>mdi-pencil</v-icon>Alterar Senha
			</v-btn>
		</template>
		<v-card outlined max-width="575">
			<v-card-title class="text-h5 pa-4">Alterar Senha</v-card-title>
			<v-divider />
			<v-card-text class="pa-4">
				<v-form>
					<v-row>
						<v-col cols="12" class="mt-2 mb-n8">
							<v-text-field
								outlined
								dense
								:disabled="carregando"
								label="Senha atual"
								:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show1 ? 'text' : 'password'"
								@click:append="show1 = !show1"
								v-model="senhaAtual"
								required
								autocomplete="off"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="mb-n8">
							<v-text-field
								outlined
								dense
								:disabled="carregando"
								label="Senha"
								:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show2 ? 'text' : 'password'"
								@click:append="show2 = !show2"
								v-model="senha"
								required
								autocomplete="off"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="pb-0">
							<v-text-field
								outlined
								dense
								:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show3 ? 'text' : 'password'"
								@click:append="show3 = !show3"
								:disabled="carregando"
								label="Confirmar senha"
								v-model="confirmarSenha"
								required
								@keydown.enter="alterarSenha()"
								autocomplete="off"
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-divider />
			<v-card-actions class="pa-4">
				<v-spacer />
				<v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
				<v-btn
					:disabled="carregando"
					:loading="carregando"
					color="primary"
					elevation="0"
					@click="alterarSenha()"
				>Confirmar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "MeuUsuarioDialogSenha",
	components: { CardDialog },
	data: () => ({
		show1: true,
		show2: true,
		show3: true,
		carregando: false,
		dialog: false,
		senhaAtual: "",
		senha: "",
		confirmarSenha: "",
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		limpar() {
			this.senhaAtual = "";
			this.senha = "";
			this.confirmarSenha = "";
		},
		alterarSenha() {
			this.carregando = true;
			if (this.senha == this.confirmarSenha) {
				return axios
					.post(`${this.backendUrl}usuario/alterarsenha`, {
						token: this.usuario.token,
						novasenha: this.senha,
						senhaatual: this.senhaAtual,
					})
					.then((res) => {
						this.carregando = false;
						if (res.data) {
							this.dialog = false;
							this.dialogErro.title = "Sucesso";
							this.dialogErro.msg = "Senha alterada com sucesso!";
							this.dialogErro.icon = "mdi-check-circle-outline";
							this.dialogErro.iconColor = "success";
							this.dialogErro.status = true;
						} else {
							this.dialogErro.title = "Erro";
							if (res.data.erro) {
								this.dialogErro.msg = res.data.erro;
							} else {
								this.dialogErro.msg =
									"Não foi possível alterar a senha!";
							}
							this.dialogErro.icon = "mdi-alert-circle-outline";
							this.dialogErro.iconColor = "red";
							this.dialogErro.status = true;
						}
					})
					.catch((e) => {
						this.carregando = false;
						this.dialogErro.title = "Erro";
						if (e.response.data.erro) {
							this.dialogErro.msg = e.response.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível alterar a senha!";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					});
			} else {
				this.carregando = false;
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"A confirmação de senha informada não confere!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
			}
		},
	},
};
</script>