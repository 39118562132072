<template>
	<v-row>
		<v-col cols="12" md="4">
			<v-card class="mx-auto" color="primary" dark>
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">ID:</span>
						{{usuario.idusuario}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-outline</v-icon>
						<span class="font-weight-bold">Nome:</span>
						{{usuario.nome}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Usuário:</span>
						{{usuario.usuario}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{usuario.filial}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-multiple</v-icon>
						<span class="font-weight-bold">Grupo:</span>
						{{usuario.grupo}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-group-outline</v-icon>
						<span class="font-weight-bold">Departamento:</span>
						{{usuario.departamento}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-clock</v-icon>
						<span class="font-weight-bold">Departamento:</span>
						{{usuario.dtultimologin}}
					</div>
				</v-card-text>
				<v-divider v-if="usuario.idgrupo == 7" />
				<v-card-actions class="pa-4" v-if="usuario.idgrupo == 7">
					<v-spacer />
					<MeuUsuarioDialogSenha />
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12" md="8">
			<UsuarioGrupoListaPermissao
				:idgrupo="usuario.idgrupo"
				:idusuario="usuario.idusuario"
				:alterarPermissao="false"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import UsuarioGrupoListaPermissao from "../Usuario/UsuarioGrupoListaPermissao.vue";
import MeuUsuarioDialogSenha from "./MeuUsuarioDialogSenha.vue";

export default {
	name: "MeuUsuario",
	components: { UsuarioGrupoListaPermissao, MeuUsuarioDialogSenha },
	data: () => ({
		carregando: false,
		msg: "",
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		alterarSenha() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/alterar`, {
					idusuario: parseInt(this.usuario.idusuario),
				})
				.then((res) => {
					this.msg = res;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
	},
};
</script>

<style scoped>
</style>